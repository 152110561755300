import { MDBTabs } from "mdb-react-ui-kit";
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { NavLink, Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import AppBar from "../components/Navbars/Navbar";
import Title from "../components/Title";
import "./GameInformation.css";
import axios from "axios";

const GameInformation = () => {
  const [gameStatus, setGameStatus] = useState(localStorage.getItem("gameStatus") || ""); // Estado local para gameStatus
  const [showExtraInfo, setShowExtraInfo] = useState(false);
  const [isClickable, setIsClickable] = useState(false); // Control de interactividad

  useEffect(() => {
    const checkGameStatus = async () => {
      const gameId = localStorage.getItem("game-id");
      if (!gameId) return;

      try {
        const response = await axios.post("https://panel.lotoxport.com/api/check-status", {
          gameId,
        });

        const { status } = response.data;
        setGameStatus(status);
        localStorage.setItem("gameStatus", status); // Actualiza localStorage con el nuevo estado

        // Define la interactividad de los botones según el estado del juego
        setIsClickable(status === "Scheduled");
      } catch (error) {
        console.error("Error al verificar el estado del juego:", error);
        setIsClickable(false);
      }
    };

    checkGameStatus();
  }, []);

  const handlePlayClick = () => {
    if (isClickable) {
      localStorage.setItem("canEdit", "true");
      // Lógica para manejar el evento del botón sin recargar la página
    }
  };

  const handleExtraInfoClick = () => {
    setShowExtraInfo((prev) => !prev); // Toggle de la visibilidad de la información extra
  };

  return (
    <div>
      <AppBar />

      <Title title={localStorage.getItem("game-name")} />

      <nav>
        <div className="item-center">
          <div className="item-center bg-color-blue-sec-small">
            <MDBTabs className="contentInformation">
              {(gameStatus === "Scheduled" || gameStatus === "InProgress") ? (
                <>
                  <div className="contentButton">
                    <NavLink className="verinfoButton" to="/GameInfo/Games">
                      <Button className="tab-btn" onClick={isClickable ? handlePlayClick : null}>
                        {isClickable ? "Juega" : "Juegos"}
                      </Button>
                    </NavLink>
                  </div>
                  <div className="contentButton">
                    <NavLink className="verinfoButton" to="/GameInfo/Users">
                      <Button className="tab-btn">Participantes</Button>
                    </NavLink>
                  </div>
                  <div className="contentButton">
                    <NavLink className="verinfoButton" to="/GameInfo/myentries">
                      <Button className="tab-btn">Mis entradas</Button>
                    </NavLink>
                  </div>
                </>
              ) : (
                <>
                  <div className="contentButton">
                    <NavLink className="verinfoButton" to="/GameInfo/Games">
                      <Button className="tab-btn">Juegos</Button>
                    </NavLink>
                  </div>
                  <div className="contentButton">
                    <NavLink className="verinfoButton" to="/GameInfo/Users">
                      <Button className="tab-btn">Resultados</Button>
                    </NavLink>
                  </div>
                  <div className="contentButton">
                    <NavLink className="verinfoButton" to="/GameInfo/myentries">
                      <Button className="tab-btn">Mis entradas</Button>
                    </NavLink>
                  </div>
                </>
              )}

              {showExtraInfo && (
                <>
                  <div className="contentButton">
                    <NavLink className="verinfoButton" to="/GameInfo/Rules">
                      <Button className="tab-btn">Reglas del torneo</Button>
                    </NavLink>
                  </div>
                  <div className="contentButton">
                    <NavLink className="verinfoButton" to="/GameInfo/Prize">
                      <Button className="tab-btn">Premios</Button>
                    </NavLink>
                  </div>
                </>
              )}

              <div className="contentButton">
                <Button className="aboutButton" onClick={handleExtraInfoClick}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="#141e47"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="12" fill="#4CAF50" />
                    <path
                      d="M12 7V13"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 17H12.01"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Button>
              </div>
            </MDBTabs>
          </div>
        </div>
      </nav>

      <Outlet />
      <Footer />
    </div>
  );
};

export default GameInformation;
