import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { toast } from "react-toastify";


export default function Subscribe() {
    const [validated, setValidated] = useState(false);
    const [email, setEmail] = useState("");  // Estado para guardar el email

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            // Realizar la solicitud POST aquí
            const response = await fetch('https://panel.lotoxport.com/api/add-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: email }), 
            });
            
            if (response.ok) {
                const result = await response.json();
                toast.success(`¡Gracias por suscribirte!`);
                console.log(result);
            } else {
                console.error('Error en la solicitud');
            }
        }

        setValidated(true);
    };

    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group>
                    <label htmlFor="Email-input" className="form-label">Correo electrónico</label>
                    <div className="d-flex justify-content-center flex-column align-items-center mb-3">
                        <input 
                            required 
                            type="email" 
                            className="form-control" 
                            id="Email-input" 
                            placeholder='Ingresa tu correo electrónico' 
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid" className='text-size-small'>
                        Ingrese un correo electrónico válido.                        </Form.Control.Feedback>
                    </div>
                </Form.Group>
                <Button type="submit" className="btn btn-green">Someter</Button>
            </Form>
        </>
    );
}
