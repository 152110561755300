import { Fragment, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../../components/GameCards.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import axios from "../../API";
import { ImgBaseUrl } from "../../ImageBaseUrl";
import styles from "./GameComponet.module.css";
import ConteoRegresivo from "./ConteoRegresivo";
import { toast } from "react-toastify";

function GameComponent() {
  const Navigate = useNavigate();
  const location = useLocation();
  
  const [ApiAllData, setApiAllData] = useState([]);
  const [ApiData, setApiData] = useState({});
  const [Page, setPage] = useState(1);
  const [Loading, setLoading] = useState(false);
  const [IsError, setIsError] = useState("");
  const [countdowns, setCountdowns] = useState([]);
  const [TimeRestante, setTimeRestate] = useState("");
  const [BoleanTime, setBoleanTime] = useState("");
  const [posicion, setPosicion] = useState(5);
  const [Filtro, setFiltro] = useState("All");
  const [entriesUser, setEntriesUser] = useState({});
  const [questions, setQuestions] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [loadingEntries, setLoadingEntries] = useState(false);
  const [selectedGameId, setSelectedGameId] = useState(null);
  const [typebreakTittle, setTypebreakTittle] = useState("");
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false); // Estado para popup de confirmación
  const [entryToDelete, setEntryToDelete] = useState(null); // Estado para la entrada seleccionada para eliminar

  var idSearch = "";
  var enpoint = "";

  const mes = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  useEffect(() => {
    const getTypebreakData = async () => {
      const gameId = localStorage.getItem("game-id");
      try {
        const response = await axios.get("https://panel.lotoxport.com/api/get-typebreak/"+gameId);
        setTypebreakTittle(response.data[0].name); // Guardar el valor de typebreak
        console.log("Typebreak fetched successfully:", response.data[0].name);
        console.log("Typebreak fetched successfully:", typebreakTittle);

      } catch (error) {
        console.error("Error al obtener el typebreak:", error);
      }
    };
  
    getTypebreakData(); 
  }, []);

  const getGames = async (w) => {
    try {
      setLoading(true);
      console.log("Fetching games, page:", w);

      let response;
      const loginStatus = localStorage.getItem("login");

      if (localStorage.getItem("game-pos") === "1") {
        idSearch = localStorage.getItem("tournament-id");
        enpoint = "get-games-tournament/" + `${idSearch}`;
        response = await axios.get(enpoint, {
          params: { page: `${w}` },
        });
      } else if (localStorage.getItem("game-pos") === "2") {
        idSearch = localStorage.getItem("category-id");
        enpoint = "get-games-category/" + `${idSearch}`;
        response = await axios.get(enpoint, {
          params: { page: `${w}` },
        });
      } else if (localStorage.getItem("game-pos") === "3") {
        enpoint = "/game-status";
        if (localStorage.getItem("banderaPeticion") === "0") {
          response = await axios.post(enpoint, {
            auth_token: localStorage.getItem("token"),
            game_status: localStorage.getItem("filtroBusqueda"),
          });
          localStorage.setItem("banderaPeticion", "1");
        } else {
          setLoading(false);
          toast.success("Peticion en proceso");
          return;
        }
      } else {
        const endpoint = "/active-games";
        const authToken = localStorage.getItem("token");

        response = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${authToken}`
          },
          params: {
            page: `${w}`
          }
        });
      }

      if (response && response.data) {
        console.log("Games fetched successfully:", response.data);

      // Función para convertir `created_at` a un objeto Date
      const parseDate = (createdAt) => {
        // Remover la zona horaria (en este caso "AST") y crear un objeto Date
        const dateWithoutTimezone = createdAt.replace(" AST", "");
        return new Date(dateWithoutTimezone);
      };

      // Ordenamos los juegos según el campo `created_at`
      const orderedGames = response.data.sort((a, b) => {
        const dateA = parseDate(a.created_at);
        const dateB = parseDate(b.created_at);
        return dateB - dateA;  // De la más reciente a la más antigua
      });


      
      // Mostramos los juegos ordenados
      console.log("Games ordered successfully:", orderedGames);
      // setApiAllData(response.data);
      setApiAllData(orderedGames);  // Guardamos los datos ya ordenados en el estado


       
      } else {
        console.error("No se recibió una respuesta adecuada del servidor.");
      }
      setLoading(false);
    } catch (error) {
      console.log("🚀error....", error);
      setLoading(false);
    }

    localStorage.setItem("banderaPeticion", "0");
  };

  const getEntriesByUser = async (gameId) => {
    try {
      setLoadingEntries(true);
      console.log("Fetching entries for game:", gameId);
      const responsy = await axios.post("entries-by-user", {
        game_id: gameId,
        auth_token: localStorage.getItem("token"),
      });

      var entriesUserResponse = responsy.data.game_users;
      setEntriesUser(prevState => ({ ...prevState, [gameId]: entriesUserResponse }));
      setLoadingEntries(false);
      console.log("Entries fetched successfully for game:", gameId, entriesUserResponse);
    } catch (error) {
      console.log("🚀errorAnswer....", error);
      setLoadingEntries(false);
    }
  };

  const getQuestions = async (gameId) => {
    try {
      console.log("Fetching questions for game:", gameId);
      const response = await axios.get(`/get-questions/${gameId}`);
      const questionsResponse = response.data;
      setQuestions(prevState => ({ ...prevState, [gameId]: questionsResponse }));
      console.log("Questions fetched successfully for game:", gameId, questionsResponse);
    } catch (error) {
      console.log("🚀error fetching questions....", error);
    }
  };

  useEffect(() => {
    getGames(1);
  }, []);

  const handleButtonClick = (idGame, idTournament, txtButton, premiacion, imgPremiacion, fecha,name) => {
    console.log("Button clicked:", { idGame, idTournament, txtButton, premiacion, imgPremiacion, fecha });
    localStorage.setItem("game-name", name);
    if (localStorage.getItem("login") === "Ok") {
      if (txtButton === "Juega Ahora" || txtButton === "Juega de nuevo") {
        const canEdit = true; // Definir canEdit como true para estos botones
        localStorage.setItem("canEdit", canEdit); // Guardar en localStorage
        localStorage.setItem("game-id", idGame);
        localStorage.setItem("idTournament", idTournament);
        localStorage.setItem("statusGame", "0");
        localStorage.setItem("premio", premiacion);
        localStorage.setItem("imgPremio", imgPremiacion);
        localStorage.setItem("fechaJuego", fecha);
        localStorage.setItem("editarJuego", "No");
        localStorage.setItem("gameStatus", "Scheduled");
        Navigate("/GameInfo/Games");
      } else {
        localStorage.setItem("game-id", idGame);
        localStorage.setItem("idTournament", idTournament);
        localStorage.setItem("statusGame", "0");
        localStorage.setItem("premio", premiacion);
        localStorage.setItem("imgPremio", imgPremiacion);
        localStorage.setItem("fechaJuego", fecha);
        if (txtButton === "Editar") {
          localStorage.setItem("editarJuego", "Si");
          setSelectedGameId(idGame);

          // Llamada a las APIs solo cuando se hace clic en "Editar"
          getEntriesByUser(idGame);
          getQuestions(idGame);
        } else {
          localStorage.setItem("editarJuego", "No");
        }
        setSelectedGameId(idGame);
        setOpenModal(true);
      }
    } else {
      Navigate("/Iniciesesion");
    }
  };

  const handleEntryClick = (entryId) => {
    console.log("Entry clicked:", entryId);
    localStorage.setItem("gameUser_id", entryId);
    localStorage.setItem("gameStatus", "Scheduled");
    Navigate("/GameInfo/Games");
  };
  

  const handleViewButtonClick = (idGame, idTournament, canEdit, gameStatus, name) => {
    console.log("View button clicked:", { idGame, idTournament, canEdit, gameStatus });
    localStorage.setItem("game-name", name);
    if (localStorage.getItem("login") === "Ok") {
      localStorage.setItem("game-id", idGame);
      localStorage.setItem("idTournament", idTournament);
      localStorage.setItem("statusGame", "1");
      localStorage.setItem("editarJuego", "No");

      if (gameStatus === "Final") {
        localStorage.setItem("gameStatus", "Final");
      }

      localStorage.setItem("canEdit", canEdit);
      if (canEdit) {

        setSelectedGameId(idGame);
        setOpenModal(true);
      } else {
        Navigate("/GameInfo/Games");
      }
    } else {
      Navigate("/Iniciesesion");
    }
  };

  const handleDeleteEntry = async (entryId, gameId) => {
    try {
      const response = await axios.post("delete-user-entry", {
        entry_id: entryId,
        auth_token: localStorage.getItem("token"),
      });

      if (response.status === 200) {
        toast.success("Entrada eliminada con éxito");
        setEntriesUser(prevState => {
          const updatedEntries = { ...prevState };
          updatedEntries[gameId] = updatedEntries[gameId].filter(entry => entry.id !== entryId);
          return updatedEntries;
        });
        setOpenConfirmDelete(false); // Cerrar confirmación
      } else {
        toast.error("Error al eliminar la entrada");
      }
    } catch (error) {
      toast.error("Error al eliminar la entrada");
    }
  };

  const openDeleteConfirmation = (entryId) => {
    setEntryToDelete(entryId); // Guardar la entrada a eliminar
    setOpenConfirmDelete(true); // Abrir el popup de confirmación
  };

  const closeDeleteConfirmation = () => {
    setOpenConfirmDelete(false); // Cerrar el popup sin eliminar
  };

  const confirmDelete = () => {
    handleDeleteEntry(entryToDelete, selectedGameId); // Ejecutar eliminación al confirmar
  };

  const ButtonGame = ({
    status,
    idTournament,
    idGame,
    userPlaying,
    imgPremiacion,
    premiacion,
    fecha,
    canPlay,
    hasPlayed,
    name,
    type,
  }) => {
    const isLoggedIn = localStorage.getItem("login") === "Ok";
  
    if (status === "Scheduled") {
      let txtButton;
  
      if (!isLoggedIn) {
        txtButton = "Juega Ahora";
      } else {
        txtButton = canPlay ? (hasPlayed ? "Juega de nuevo" : "Juega Ahora") : "Editar";
      }
  
      return (
        <div>
          {/* Botón principal (Editar o Juega Ahora) */}
          <button
            onClick={() => {
              const canEdit = txtButton === "Editar";  // Si es "Editar", se puede editar
              localStorage.setItem("canEdit", canEdit);  // Guardar en localStorage
              localStorage.setItem("game-id", idGame);
              localStorage.setItem("idTournament", idTournament);
              handleButtonClick(idGame, idTournament, txtButton, premiacion, imgPremiacion, fecha,name);
            }}
            className={txtButton === "Editar" ? styles.btn_gameCard_2 : styles.btn_gameCard_1}
          >
            {`${txtButton}`}
          </button>
  
          {/* Si el botón es "Editar", mostramos también el botón "Ver ?" */}
          {txtButton === "Editar" && (
            <button
              onClick={() => {
                const canEdit = false; // "Ver ?" siempre tendrá canEdit como false
                localStorage.setItem("canEdit", canEdit); // Guardar en localStorage
                localStorage.setItem("game-id", idGame);
                localStorage.setItem("idTournament", idTournament);
                handleViewButtonClick(idGame, idTournament, canEdit, "Final", name);
              }}
              className={styles.btn_gameCard_1}
            >
              Ver
            </button>
          )}
        </div>
      );
    } else {
      return (
        <button
          onClick={() => {
            const canEdit = false; // En este caso, siempre será false
            localStorage.setItem("canEdit", canEdit); // Guardar en localStorage
            localStorage.setItem("game-id", idGame);
            localStorage.setItem("idTournament", idTournament);
            handleViewButtonClick(idGame, idTournament, canEdit, status, name);
          }}
          className={styles.btnTimer}
        >
          Ver
        </button>
      );
    }
  };
  

  const NumParticipantes = (userPlaying) => {
    if (userPlaying == null) {
      return "0";
    } else {
      return userPlaying.length;
    }
  };

  const MostarRestante = ({ status }) => {
    if (status === "Scheduled") {
      return null;
    } else {
      return <span className={styles.text_min2}>{" Tiempo restante"}</span>;
    }
  };

  return (
    <Fragment>
      {Page > 1 || !Loading ? (
        <div className="d-flex flex-column align-items-center">
          <div className={styles.game_card_parent_width_div}>
            {ApiAllData.length > 0 ? (
              ApiAllData.map((item, index) => {
                return (
                  <div key={index} className={styles.main_game_card_body}>
                    <div className="main-game-card-head">
                      <div className="game-card-head-date-div">
                        <div>
                          <span className={styles.labelName}>{item.name}</span>
                        </div>
                        <div>
                          <span className="white game-card-head-txt">
                            {mes[parseInt(item.date_start[1]) - 1]} {item.date_start[2]}, {item.date_start[0]}
                          </span>
                        </div>
                      </div>
                      <div className="game-card-head-fees-div">
                        <span className="color-green game-card-head-txt">Entry fee ${item.price}</span>
                      </div>
                      <div className="game-card-head-vacancy-div">
                        <span className="white game-card-head-txt">
                          {NumParticipantes(item.users_answered)} de {item.members} participantes
                        </span>
                      </div>
                    </div>
                    <div
                      className={styles.mainGameCardContent}
                      style={{
                        background: `url(${item.background_img})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    >
                      <div className={styles.overlay}>
                        <div className={styles.cardContentElements}>
                          {item.status !== "Final" && item.status !== null && item.status !== "InProgress" ? (
                            <div className={styles.contentTextTime}>
                              <MostarRestante status={item.status} />
                              <ConteoRegresivo
                                anio={parseInt(item.date_start[0])}
                                mes={parseInt(item.date_start[1])}
                                dia={parseInt(item.date_start[2])}
                                hora={parseInt(item.time_start[0])}
                                minuto={parseInt(item.time_start[1])}
                                status={item.status}
                              />
                            </div>
                          ) : (
                            <> </>
                          )}
                         <div className={styles.contentButtonGame}>
                            <ButtonGame
                              status={item.status}
                              idGame={item.game_id}
                              idTournament={item.idTournament}
                              userPlaying={item.users_answered}
                              imgPremiacion={item.award_image}
                              premiacion={item.awards}
                              fecha={`${item.date_close[2]}/${item.date_close[1]}/${item.date_close[0]} a las ${item.time_close[0]}:${item.time_close[1]}:${item.time_close[2]}`}
                              canPlay={item.can_play}
                              hasPlayed={item.has_played}
                              name={item.name}
                              type={item.type} // Asegúrate de pasar el campo type
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>
                {location.pathname === "/Juegos/Category" && (
                  <p>En el momento no hay juegos disponibles en esta categoría. Intenta en otras categorías.</p>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <> </>
      )}
      {Loading && (
        <div>
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...modalStyle }}>
          <h2 className={styles.modal_modal_title} id="modal-modal-title">
            Tus entradas:  
          </h2>
          {loadingEntries ? (
              <CircularProgress color="inherit" />
            ) : ( 
              <Box sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
                {entriesUser[selectedGameId] &&
                  entriesUser[selectedGameId].map((entry, index) => (
                    <div key={index} style={{ padding: '10px', margin: '10px 0' }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '2%' }}>
                        <p style={{ color: '#02a551' }}>{`Entrada ${index + 1}`}</p>
                        <p onClick={() => handleEntryClick(entry.id)}>{`Editar `}</p>
                        {/* Botón Eliminar agregado */}
                        <p onClick={() => openDeleteConfirmation(entry.id)}>
                          {`Eliminar`}
                        </p>
                      </div>
                      {entry.user_answers &&
                        entry.user_answers.map((answer, i) => (
                          <div
                            key={i}
                            style={{
                              backgroundColor: '#e0e1e2',
                              padding: '2%',
                              borderRadius: '15px',
                              marginBottom: '10px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <p style={{ marginBottom: '0', width: '80%' }}>
                              <strong>
                                {questions[selectedGameId] && questions[selectedGameId][i]
                                  ? questions[selectedGameId][i].game_ques
                                  : `Pregunta ${i + 1}`}
                              </strong>
                              <br />
                              {answer}
                            </p>

                            <div onClick={() => handleEntryClick(entry.id)} className={styles.modal_modal_arrow}>
                              <div className="Tab4Content_imgArrow__2NRvA">
                                <svg width="13" height="29" viewBox="0 0 23 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M21.6774 17.4594L5.40486 1.21565C5.13759 0.946185 4.81961 0.732302 4.46927 0.586342C4.11892 0.440383 3.74314 0.365234 3.36361 0.365234C2.98407 0.365234 2.60829 0.440383 2.25795 0.586342C1.9076 0.732302 1.58963 0.946185 1.32236 1.21565C0.786885 1.75432 0.486328 2.483 0.486328 3.24253C0.486328 4.00206 0.786885 4.73074 1.32236 5.26941L15.5536 19.6444L1.32236 33.8757C0.786885 34.4143 0.486328 35.143 0.486328 35.9025C0.486328 36.6621 0.786885 37.3907 1.32236 37.9294C1.58862 38.2011 1.90615 38.4172 2.25654 38.5652C2.60694 38.7133 2.98322 38.7903 3.36361 38.7919C3.74399 38.7903 4.12028 38.7133 4.47067 38.5652C4.82106 38.4172 5.13859 38.2011 5.40486 37.9294L21.6774 21.6857C21.9692 21.4164 22.2021 21.0897 22.3614 20.726C22.5207 20.3623 22.6029 19.9696 22.6029 19.5725C22.6029 19.1755 22.5207 18.7827 22.3614 18.4191C22.2021 18.0554 21.9692 17.7286 21.6774 17.4594Z"
                                    fill="#02A551"
                                  ></path>
                                </svg>
                              </div>
                            </div>
                          </div>
                        ))}
                      {/* Obtener el juego seleccionado basado en selectedGameId */}
                      {ApiAllData.find(game => game.game_id === selectedGameId)?.type === 'juego' && (
                        <div
                          style={{
                            backgroundColor: '#e0e1e2',
                            padding: '2%',
                            borderRadius: '15px',
                            marginBottom: '10px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <p style={{ marginBottom: '0', width: '80%' }}>
                            <strong>{typebreakTittle || "Desempate"}</strong>
                            <br />
                            {entry.typebreak}
                          </p>
                          <div className={styles.modal_modal_arrow}>
                            <div className="Tab4Content_imgArrow__2NRvA">
                              <svg width="13" height="29" viewBox="0 0 23 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M21.6774 17.4594L5.40486 1.21565C5.13759 0.946185 4.81961 0.732302 4.46927 0.586342C4.11892 0.440383 3.74314 0.365234 3.36361 0.365234C2.98407 0.365234 2.60829 0.440383 2.25795 0.586342C1.9076 0.732302 1.58963 0.946185 1.32236 1.21565C0.786885 1.75432 0.486328 2.483 0.486328 3.24253C0.486328 4.00206 0.786885 4.73074 1.32236 5.26941L15.5536 19.6444L1.32236 33.8757C0.786885 34.4143 0.486328 35.143 0.486328 35.9025C0.486328 36.6621 0.786885 37.3907 1.32236 37.9294C1.58862 38.2011 1.90615 38.4172 2.25654 38.5652C2.60694 38.7133 2.98322 38.7903 3.36361 38.7919C3.74399 38.7903 4.12028 38.7133 4.47067 38.5652C4.82106 38.4172 5.13859 38.2011 5.40486 37.9294L21.6774 21.6857C21.9692 21.4164 22.2021 21.0897 22.3614 20.726C22.5207 20.3623 22.6029 19.9696 22.6029 19.5725C22.6029 19.1755 22.5207 18.7827 22.3614 18.4191C22.2021 18.0554 21.9692 17.7286 21.6774 17.4594Z"
                                    fill="#02A551"
                                  ></path>
                              </svg>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </Box>
            )}

        </Box>
      </Modal>

      {/* Modal de confirmación de eliminación */}
      <Modal
        open={openConfirmDelete}
        onClose={closeDeleteConfirmation}
        aria-labelledby="modal-modal-title-confirm"
        aria-describedby="modal-modal-description-confirm"
      >
        <Box sx={{ ...modalStyle }}>
          <h2 id="modal-modal-title-confirm">¿Estás seguro de que deseas eliminar esta entrada?</h2>
          <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2 }}>
            <button onClick={confirmDelete} className={styles.btnConfirm}>Eliminar</button>
            <button onClick={closeDeleteConfirmation} className={styles.btnCancel}>Cancelar</button>
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #02a551',
  borderRadius: '15px',
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  p: 4,
  width: '40%',
  '@media (max-width: 600px)': {
    width: '80%',
  },
};

export default GameComponent;
