import React, { useState, useEffect, Fragment } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import axios from "../../../API";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import styles from "./Tab4Content.module.css";

const EntrysGame = ({
  EntrysData = [],
  VisibilityA,
  setVisibilityA,
  setAnswers,
  setPosition,
  Position,
  Question = [],
  Answers = [],
  Loading,
}) => {
  const ico = (
    <svg
      width="13"
      height="29"
      viewBox="0 0 23 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6774 17.4594L5.40486 1.21565C5.13759 0.946185 4.81961 0.732302 4.46927 0.586342C4.11892 0.440383 3.74314 0.365234 3.36361 0.365234C2.98407 0.365234 2.60829 0.440383 2.25795 0.586342C1.9076 0.732302 1.58963 0.946185 1.32236 1.21565C0.786885 1.75432 0.486328 2.483 0.486328 3.24253C0.486328 4.00206 0.786885 4.73074 1.32236 5.26941L15.5536 19.6444L1.32236 33.8757C0.786885 34.4143 0.486328 35.143 0.486328 35.9025C0.486328 36.6621 0.786885 37.3907 1.32236 37.9294C1.58862 38.2011 1.90615 38.4172 2.25654 38.5652C2.60694 38.7133 2.98322 38.7903 3.36361 38.7919C3.74399 38.7903 4.12028 38.7133 4.47067 38.5652C4.82106 38.4172 5.13859 38.2011 5.40486 37.9294L21.6774 21.6857C21.9692 21.4164 22.2021 21.0897 22.3614 20.726C22.5207 20.3623 22.6029 19.9696 22.6029 19.5725C22.6029 19.1755 22.5207 18.7827 22.3614 18.4191C22.2021 18.0554 21.9692 17.7286 21.6774 17.4594Z"
        fill="#02A551"
      />
    </svg>
  );

  const VerAnswers = () => {
    if (VisibilityA) {
      return (
        <div>
          {Answers.map((item, index) => {
            const currentQuestion = Question[index];
            const isTypebreak = currentQuestion?.typebreak;
            const typebreakTittle = currentQuestion?.typebreak_tittle;
            const gameResultSum =
              parseInt(currentQuestion?.game_result_1 || 0) +
              parseInt(currentQuestion?.game_result_2 || 0);

            return (
              <Fragment key={index}>
                <div className={styles.contentEntrysAns}>
                  <div className={styles.contentPosition}>
                    <span className={styles.txtPosition}>{index + 1}</span>
                  </div>

                  <div className={styles.contentInfoJugadores}>
                    <div className={styles.contentNameEntrys}>
                      <div className={styles.subContentName}>
                        <div className={styles.nameContent2}>
                          <div className={styles.divSeparator}>
                            <span className={styles.txtQuestion}>
                              {isTypebreak
                                ? typebreakTittle || "Desempate"
                                : currentQuestion?.game_ques ||
                                  "Pregunta no disponible"}
                            </span>
                          </div>
                          <div className={styles.divSeparator}>
                            <span className={styles.txtAnswerUser}>
                              {item}
                            </span>
                          </div>
                        </div>
                        <div className={styles.contentScore}>
                          <div className={styles.titleScore}>
                            <span className={styles.txtPuntuacionNor}>
                              {isTypebreak ? "RESULTADO" : "PUNTUACIÓN"}
                            </span>
                          </div>
                          <div className={styles.titleScore}>
                            <span className={styles.txtScore}>
                              {isTypebreak
                                ? gameResultSum
                                : EntrysData[Position]?.score[index] || 0}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="p-2"></div>
              </Fragment>
            );
          })}
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <Fragment>
      {!Loading &&
        EntrysData.map((item, index) => (
          <Fragment key={index}>
            <div className={styles.contentEntrys}>
              <div className={styles.contentPosition}></div>
              <div
                className={styles.contentInfoJugadores2}
                onClick={() => {
                  setVisibilityA(!VisibilityA);
                  setPosition(index);
                  setAnswers(item.user_answers || []);
                }}
              >
                <div className={styles.contentImage}>
                  <div className={styles.subContentImage}>
                    <img
                      className={styles.imageUser}
                      src={item.user_profile_image}
                    />
                  </div>
                </div>

                <div className={styles.contentName}>
                  <div className={styles.subContentName}>
                    <div className={styles.nameCont}>
                      <span className={styles.txtNamePicks}>
                        {item.user_username}{" "}
                      </span>
                      <span className={styles.txtNormal}>
                        tiene un total de{" "}
                      </span>
                      <span className={styles.txtScorePicks}>
                        {item.user_score}{" "}
                      </span>
                      <span className={styles.txtNormal}>pts{" "}</span>
                    </div>
                  </div>
                </div>
                <div className={styles.contentButton}>
                  <div className={styles.subContentButton}>
                    <div className={styles.imgArrow}>{ico}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-2"></div>

            <div className={styles.contentAnswUser}>
              {index === Position && <VerAnswers />}
            </div>
          </Fragment>
        ))}
    </Fragment>
  );
};

const Tab5Content = () => {
  const [Loading, setLoading] = useState(false);
  const [ApiData, setApiData] = useState([]);
  const [EntrysData, setEntrysData] = useState([]);
  const [Question, setQuestion] = useState([]);
  const [showEntrys, setShowEntrys] = useState([]);
  const [noDataMessage, setNoDataMessage] = useState(false);

  const Navigate = useNavigate();

  const getData = async () => {
    try {
      setLoading(true);
      var idSearch = localStorage.getItem("game-id");
      const enpoint = "get-questions/" + `${idSearch}`;
      const res = await axios.get(enpoint, {});
      setLoading(false);
      setQuestion(res.data || []);
    } catch (error) {
      console.log("🚀error....", error);
      setLoading(false);
      setQuestion([]); // Fallback to empty array in case of error
    }
  };

  const getEntrys = async () => {
    try {
      setLoading(true);
      const res = await axios.post("/entries-for-one-user", {
        game_id: localStorage.getItem("game-id"),
        auth_token: localStorage.getItem("token"),
      });
      // console.log("🚀 ~ file: Tab5Content.js ~ line 202 ~ getEntrys ~ res", res.data);
      if (res.data.length === 0) {
        setNoDataMessage(true); // Activar el mensaje si no hay datos
      } else {
        setNoDataMessage(false); // Ocultar mensaje si hay datos
      }
      // console.log("🚀 ~ file: Tab5Content.js ~ line 202 ~ noDataMessage ~ noDataMessage", noDataMessage);
      setApiData(res.data || {});
      setEntrysData(res.data.game_users || []);
      setShowEntrys(new Array((res.data.game_users || []).length).fill(false));
      setLoading(false);
    } catch (error) {
      console.log("🚀error....", error);
      setLoading(false);
      setEntrysData([]); // Fallback to empty array in case of error
    }
  };

  useEffect(() => {
    getEntrys();
    getData();
  }, []);

  const handleShowEntrysClick = (index) => {
    const updatedShowEntrys = [...showEntrys];
    updatedShowEntrys[index] = !updatedShowEntrys[index];
    setShowEntrys(updatedShowEntrys);
  };

  return (
    <>
      {Loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}

      <div className="p-4"></div>
      <div className="item-center">
        <div className="width-tab4content">
          <div className={styles.contentSubTitle}>
            <span className={styles.txtSubtituloPicks}></span>
          </div>
          <div className="p-2"></div>

          {/* Mostrar mensaje si no hay datos */}
          {noDataMessage ? (
            <div className={styles.noDataMessage}>
              <span>No realizaste entradas en este juego.</span>
            </div>
          ) : (
            <EntrysGameWrapper
              showEntrys={showEntrys}
              handleShowEntrysClick={handleShowEntrysClick}
              gameUsers={EntrysData}
              Question={Question}
              Loading={Loading}
            />
          )}
          <div className="p-5"></div>
        </div>
      </div>
      <Outlet />
    </>
  );
};

const EntrysGameWrapper = ({
  showEntrys,
  handleShowEntrysClick,
  gameUsers = [],
  Question = [],
  Loading,
}) => {
  return (
    <>
      {gameUsers.map((entrysData, index) => {
        const [VisibilityA, setVisibilityA] = useState(false);
        const [Position, setPosition] = useState(100);
        const [Answers, setAnswers] = useState([]);

        return (
          <Fragment key={index}>
            <div
              className={styles.contentEntrys}
              onClick={() => handleShowEntrysClick(index)}
            >
              <div className={styles.contentPosition}>
                <span className={styles.txtPosition}>{index + 1}</span>
              </div>
              <div className={styles.contentInfoJugadores}>
                <div className={styles.contentImage}>
                  <div className={styles.subContentImage}>
                    <img
                      className={styles.imageUser}
                      src={entrysData[0]?.user_profile_image || ""}
                      alt="Profile"
                    />
                  </div>
                </div>
                <div className={styles.contentName}>
                  <div className={styles.subContentName}>
                    <div className={styles.nameCont}>
                      <span className={styles.txtNamePicks}>
                        {entrysData[0]?.user_username || "Usuario desconocido"}{" tiene "}
                        {entrysData[0]?.entries_cont || 0}{" "}
                        {entrysData[0]?.entries_cont === 1 ? "entrada de" : "entradas de"}{" "}
                        {entrysData[0]?.entries || 0}{" "}
                        {entrysData[0]?.entries === 1 ? "posible" : "posibles"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles.contentButton}>
                  <div className={styles.subContentButton}>
                    <div className={styles.imgArrow}>
                      <svg
                        width="13"
                        height="29"
                        viewBox="0 0 23 39"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.6774 17.4594L5.40486 1.21565C5.13759 0.946185 4.81961 0.732302 4.46927 0.586342C4.11892 0.440383 3.74314 0.365234 3.36361 0.365234C2.98407 0.365234 2.60829 0.440383 2.25795 0.586342C1.9076 0.732302 1.58963 0.946185 1.32236 1.21565C0.786885 1.75432 0.486328 2.483 0.486328 3.24253C0.486328 4.00206 0.786885 4.73074 1.32236 5.26941L15.5536 19.6444L1.32236 33.8757C0.786885 34.4143 0.486328 35.143 0.486328 35.9025C0.486328 36.6621 0.786885 37.3907 1.32236 37.9294C1.58862 38.2011 1.90615 38.4172 2.25654 38.5652C2.60694 38.7133 2.98322 38.7903 3.36361 38.7919C3.74399 38.7903 4.12028 38.7133 4.47067 38.5652C4.82106 38.4172 5.13859 38.2011 5.40486 37.9294L21.6774 21.6857C21.9692 21.4164 22.2021 21.0897 22.3614 20.726C22.5207 20.3623 22.6029 19.9696 22.6029 19.5725C22.6029 19.1755 22.5207 18.7827 22.3614 18.4191C22.2021 18.0554 21.9692 17.7286 21.6774 17.4594Z"
                          fill="#02A551"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {showEntrys[index] && (
              <EntrysGame
                EntrysData={entrysData}
                VisibilityA={VisibilityA}
                setVisibilityA={setVisibilityA}
                setAnswers={setAnswers}
                setPosition={setPosition}
                Position={Position}
                Question={Question}
                Answers={Answers}
                Loading={Loading}
              />
            )}
          </Fragment>
        );
      })}
    </>
  );
};

export default Tab5Content;
